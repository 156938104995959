import React, { useEffect, useRef, useState } from "react";
import "./whoweare.css";
import logo from "./mark.png";
import ceo from "./ceo.png";
import ceo1 from "./eye.png";
import ceo2 from "./eye-2.png";
import logo2 from "../../assets/images/logo2.svg";
import Typed from "typed.js";
import { isMobile } from "react-device-detect";

const WhoWeAre = () => {
  const typeRef = useRef(null);
  const [delay, setDelay] = useState(1000000000);
  const [ceoPic, setCeoPic] = useState(ceo);

  useEffect(() => {
    const typed = new Typed(typeRef.current, {
      strings: [
        "Hello World! You know me, but you don't know our super team. We are young, talented, and creative, with qualifications and experience. Our developers, designers  and brand managers help you enter the digital world and build your brand. We are interested in your goals. But our mission is to combine consumers' desires, thoughts, behaviors, and modern technologies.       Are you ready to Scriptify your brand? Then follow us!",
      ],
      startDelay: delay,
      typeSpeed: 20,
    });
  }, [delay]);
  const eyeClick = () => {
    setCeoPic(ceo1);
    setTimeout(() => {
      setCeoPic(ceo2);
      setTimeout(() => {
        setCeoPic(ceo1);
        setTimeout(() => {
          setCeoPic(ceo);
        }, 75);
      }, 75);
    }, 75);
  };

  return (
    <div
      className="container"
      onMouseOver={() => delay > 100000000 && setDelay(500)}
    >
      <div className="cont-head">
        <h2>WHO WE ARE</h2>
        <img src={logo} />
      </div>
      <div className="cont-all">
        <div className="content pixel-corners">
          <p className="content-txt">
            Hello World! You know me, but you don't know our super team. We are
            young, talented, and creative, with qualifications and experience.
            Our developers, designers and brand managers help you enter the
            digital world and build your brand. We are interested in your goals.
            But our mission is to combine consumers' desires, thoughts,
            behaviors, and modern technologies. Are you ready to Scriptify your
            brand? Then follow us!
          </p>

          <p className="type-anim" ref={typeRef}></p>
        </div>
      </div>
      <div className="group">
        <div className="person">
          <img
            src={ceoPic}
            className="ceo"
            onClick={() => eyeClick()}
            alt="ceo"
          />
          <div className="person-cont">
            <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>SCRRR...</h2>
            <h3>CEO</h3>
          </div>
        </div>
      </div>
      <div id="contact" />
    </div>
  );
};
export default WhoWeAre;
