import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import dots from "../../assets/images/dots.svg";
import sharb from "../../assets/images/sharb.svg";
import mobile from "../../assets/images/mobile.svg";
import web from "../../assets/images/web.svg";
import bc from "../../assets/images/bc.svg";
import game from "../../assets/images/game.svg";
import design from "../../assets/images/design.svg";
import Grid from "../grid/Grid";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const LOGO = [
  121, 141, 161, 181, 201, 221, 241, 261, 282, 262, 242, 222, 202, 182, 162,
  142, 122, 102, 123, 103, 84, 64, 84, 65, 45, 66, 46, 67, 87, 88, 107, 108,
  109, 128, 129, 130, 149, 150, 151, 171, 9, 10, 11, 29, 30, 31, 32, 51, 52, 53,
  72, 74, 74, 75, 83, 94, 95, 96, 114, 115, 116, 117, 136, 137, 156, 157, 175,
  176, 177, 193, 194, 195, 196, 212, 213, 114, 115, 232, 214, 215, 231, 232,
  233, 234, 249, 250, 251, 252, 269, 270, 271, 289, 290, 165, 185, 186, 205,
  206, 225, 226, 245, 246, 265, 266, 285, 286, 305, 306, 325, 326, 345, 346,
  347, 366, 367, 368, 369, 370, 3867, 388, 389, 349, 350, 351, 352, 331, 332,
  333, 334, 312, 313, 314, 315, 294, 295, 296, 275, 276, 266, 73, 44, 63, 82,
  86, 170,
];

const GAME = [
  9, 29, 50, 70, 89, 109, 129, 130, 131, 112, 113, 114, 115, 136, 156, 176, 175,
  154, 173, 194, 197, 217, 237, 257, 278, 298, 318, 338, 357, 376, 375, 374,
  353, 332, 312, 292, 272, 251, 250, 249, 248, 247, 266, 286, 306, 326, 345,
  364, 363, 362, 341, 320, 300, 280, 260, 241, 221, 201, 181, 162, 163, 164,
  165, 184, 144, 142, 122, 103, 104, 105, 106, 127, 128, 168, 170,
];

const WEB = [
  5, 25, 45, 65, 85, 105, 125, 145, 165, 185, 205, 225, 245, 265, 285, 266, 247,
  228, 249, 269, 290, 310, 331, 351, 372, 373, 354, 334, 314, 313, 293, 272,
  252, 231, 211, 212, 213, 214, 215, 194, 173, 152, 131, 110, 89, 68, 47, 26,
];

const MOB = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 24, 44, 64, 84, 104, 124, 144, 164, 184,
  204, 224, 244, 264, 284, 304, 324, 344, 364, 35, 55, 75, 95, 115, 135, 155,
  175, 195, 215, 235, 255, 275, 295, 315, 335, 355, 375, 385, 386, 387, 388,
  389, 390, 391, 392, 393, 394, 47, 48, 49, 50, 51, 52, 309, 310,
];

const DESIGN = [
  300, 320, 340, 360, 380, 381, 382, 383, 384, 361, 341, 362, 301, 281, 301,
  323, 344, 365, 346, 327, 308, 289, 270, 251, 232, 213, 194, 175, 156, 137,
  118, 99, 79, 58, 38, 37, 16, 15, 34, 53, 72, 91, 110, 129, 148, 167, 186, 205,
  224, 243, 262, 165, 144, 123, 102, 81, 61, 42, 22, 23, 24, 45, 66, 87, 108,
  234, 255, 256, 277, 278, 298, 318, 317, 316, 336, 337, 338, 355, 356, 357,
  358, 375, 376, 377, 378, 394, 395, 396, 397, 398, 399, 352, 332, 312, 291,
  293, 274, 302, 373, 304, 285, 266, 247, 228, 209, 190, 171, 152, 133, 114, 95,
  74, 116,
];

const BLOCKCHAIN = [
  48, 49, 50, 71, 92, 112, 132, 152, 171, 190, 189, 188, 167, 146, 126, 106, 86,
  67, 88, 90, 109, 129, 149, 169, 153, 174, 195, 215, 235, 236, 257, 278, 298,
  318, 338, 357, 376, 375, 374, 353, 332, 312, 292, 272, 253, 234, 274, 276,
  295, 315, 335, 355, 371, 390, 389, 368, 347, 327, 307, 267, 246, 225, 224,
  223, 242, 261, 281, 301, 321, 342, 363, 364, 346, 344, 324, 304, 284, 263,
  265, 204, 184, 165, 352, 287, 365, 166,
];

const DotAnimStatic = () => {
  const [pixs, setPixs] = useState([]);
  const [track, setTrack] = useState(false);

  useEffect(() => {
    setPixs([]);
    setTrack((prevState) => !prevState);
  }, []);

  useEffect(() => {
    let res = [];
    let random = Math.floor(Math.random() * 15) + 1; // 1
    let pos = Math.floor(Math.random() * (15 - random)) - 1; // 4
    pos = pos < 0 ? 0 : pos;
    let right = Math.random() > 0.5 ? true : false;

    for (let i = 0; i < 20 - random; i++) {
      if (pos === i) {
        for (let j = 0; j < random; j++) {
          res.push({ i, right });
        }
      } else {
        res.push({ i });
      }
    }

    setPixs(res);
  }, [track]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "60%",
        alignSelf: "flex-end",
        marginLeft: "12px",
      }}
    >
      {pixs.map((el) => (
        <div
          key={el.i * Math.random()}
          style={{
            backgroundColor: "#F32735",
            width: "5%",
            height: "33.3%",
            alignSelf: el.hasOwnProperty("right")
              ? el.right
                ? "flex-end"
                : "flex-start"
              : "center",
          }}
        />
      ))}
    </div>
  );
};

const DotAnim = () => {
  const [pixs, setPixs] = useState([]);
  const [track, setTrack] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setPixs([]);
      setTrack((prevState) => !prevState);
    }, 500);
  }, []);

  useEffect(() => {
    let res = [];
    let random = Math.floor(Math.random() * 15) + 1; // 1
    let pos = Math.floor(Math.random() * (15 - random)) - 1; // 4
    pos = pos < 0 ? 0 : pos;
    let right = Math.random() > 0.5 ? true : false;

    for (let i = 0; i < 20 - random; i++) {
      if (pos === i) {
        for (let j = 0; j < random; j++) {
          res.push({ i, right });
        }
      } else {
        res.push({ i });
      }
    }

    setPixs(res);
  }, [track]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "60%",
        alignSelf: "flex-end",
        marginLeft: "12px",
      }}
    >
      {pixs.map((el) => (
        <div
          key={el.i * Math.random()}
          style={{
            backgroundColor: "white",
            width: "5%",
            height: "33.3%",
            alignSelf: el.hasOwnProperty("right")
              ? el.right
                ? "flex-end"
                : "flex-start"
              : "center",
          }}
        />
      ))}
    </div>
  );
};

const WhiteBox = ({ text1, text2, right1, right2, bottom1, bottom2 }) => {
  return (
    <>
      <div
        className="pixel-corners1"
        style={{ right: `${right1}%`, marginBottom: `${bottom1}%` }}
      >
        {`${text1}`}
      </div>
      <div
        className="pixel-corners1"
        style={{ right: `${right2}%`, marginBottom: `${bottom2}%` }}
      >
        {`${text2}`}
      </div>
    </>
  );
};

const Services = () => {
  const [service, setService] = useState();
  const [img, setImage] = useState(sharb);
  const [showDots, setShowDots] = useState(false);
  const [width, setWidth] = useState(414);
  const navigate = useNavigate();

  const handleIndexesChange = (indexes) => {
    setSelectedIndexes(indexes);
  };

  const [selectedIndexes, setSelectedIndexes] = useState(LOGO);

  const [boxes, setBoxes] = useState(null);

  useEffect(() => {
    setImage(null);
    setShowDots(true);

    setShowDots(false);
    if (service === "mobile") {
      setImage(mobile);
      setWidth(260);
      setBoxes({
        text1: "<FRONT>",
        text2: "<BACK>",
        right1: 30,
        right2: 6,
        bottom1: 0,
        bottom2: 30,
      });
    } else if (service === "web") {
      setImage(web);
      setWidth(284);
      setBoxes({
        text1: "<FRONT>",
        text2: "<BACK>",
        right1: 20,
        right2: 8,
        bottom1: 35,
        bottom2: 20,
      });
    } else if (service === "bc") {
      setImage(bc);
      setWidth(492);
      setBoxes({
        text1: "<WEB3>",
        text2: "<SMART CONTRACT>",
        right1: 40,
        right2: 5,
        bottom1: 15,
        bottom2: -40,
      });
    } else if (service === "game") {
      setImage(game);
      setWidth(489);
      setBoxes({
        text1: "<2D>",
        text2: "<3D>",
        right1: 35,
        right2: 10,
        bottom1: 25,
        bottom2: 35,
      });
    } else if (service === "design") {
      setImage(design);
      setWidth(497);
      setBoxes({
        text1: "<UI/UX>",
        text2: "<BRANDING>",
        right1: 35,
        right2: 15,
        bottom1: 35,
        bottom2: 0,
      });
    } else {
      setBoxes(null);
    }
  }, [service]);

  const pixels = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ];

  const world = document.getElementsByClassName("world");

  return (
    <div className="wc-bot">
      <div className="wcb-left">
        <div
          className="wc-items"
          style={{ marginTop: 0, cursor: "pointer" }}
          onMouseEnter={() => {
            handleIndexesChange(WEB);
            setService("web");
          }}
          onMouseLeave={() => {
            setBoxes(null);
            setService(null);
            setSelectedIndexes(LOGO);
          }}
          onClick={() => {
            if (!isMobile) {
              navigate("/skills/web");
              world[0].style.display = "none";
            }
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <h2
              className="wc-item"
              style={{ color: service === "web" ? "#FFFFFF" : "#F32735" }}
            >
              WEB
            </h2>
            {service === "web" ? <DotAnim /> : <DotAnimStatic />}
          </div>
        </div>
        <div
          className="wc-items"
          onMouseEnter={() => {
            handleIndexesChange(MOB);
            setService("mobile");
          }}
          onMouseLeave={() => {
            setBoxes(null);
            setService(null);
            setSelectedIndexes(LOGO);
          }}
          onClick={() => {
            if (!isMobile) {
              navigate("/skills/web");
              world[0].style.display = "none";
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex" }}>
            <h2
              className="wc-item"
              style={{ color: service === "mobile" ? "#FFFFFF" : "#F32735" }}
            >
              MOBILE
            </h2>
            {service === "mobile" ? <DotAnim /> : <DotAnimStatic />}
          </div>
        </div>
        <div
          className="wc-items"
          onMouseEnter={() => {
            handleIndexesChange(BLOCKCHAIN);
            setService("bc");
          }}
          onClick={() => {
            if (!isMobile) {
              navigate("/skills/blockchain");
              world[0].style.display = "none";
            }
          }}
          onMouseLeave={() => {
            setBoxes(null);
            setService(null);
            setSelectedIndexes(LOGO);
          }}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex" }}>
            <h2
              className="wc-item"
              style={{ color: service === "bc" ? "#FFFFFF" : "#F32735" }}
            >
              BLOCKCHAIN
            </h2>
            {service === "bc" ? <DotAnim /> : <DotAnimStatic />}
          </div>
        </div>
        <div
          className="wc-items"
          onMouseEnter={() => {
            handleIndexesChange(GAME);

            setService("game");
          }}
          onClick={() => {
            if (!isMobile) {
              navigate("/skills/game");
              world[0].style.display = "none";
            }
          }}
          onMouseLeave={() => {
            setBoxes(null);
            setService(null);
            setSelectedIndexes(LOGO);
          }}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex" }}>
            <h2
              className="wc-item"
              style={{ color: service === "game" ? "#FFFFFF" : "#F32735" }}
            >
              GAME
            </h2>
            {service === "game" ? <DotAnim /> : <DotAnimStatic />}
          </div>
        </div>
        <div
          className="wc-items"
          onMouseEnter={() => {
            handleIndexesChange(DESIGN);

            setService("design");
          }}
          onClick={() => {
            if (!isMobile) {
              navigate("/skills/design");
              world[0].style.display = "none";
            }
          }}
          onMouseLeave={() => {
            setBoxes(null);
            console.log("GAVEDI", boxes);
            setService(null);
            setSelectedIndexes(LOGO);
          }}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex" }}>
            <h2
              className="wc-item"
              style={{ color: service === "design" ? "#FFFFFF" : "#F32735" }}
            >
              DESIGN
            </h2>
            {service === "design" ? <DotAnim /> : <DotAnimStatic />}
          </div>
        </div>
      </div>
      <div className="wcb-right">
        <div className="wcbr-bot ">
          <Grid indexes={selectedIndexes} speed={30} />
          {!showDots && boxes && (
            <WhiteBox
              text1={boxes?.text1}
              text2={boxes?.text2}
              right1={boxes?.right1}
              right2={boxes?.right2}
              bottom1={boxes?.bottom1}
              bottom2={boxes?.bottom2}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Services;
