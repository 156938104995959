import React, { useRef, useState } from "react";
import logo from "../../assets/images/logo.png";
import scroll from "../../assets/images/scroll.png";
import sentImg from "../../assets/images/sent.png";
import "./contact.css";
import emailjs from "@emailjs/browser";
import ReactLoading from "react-loading";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [text, setText] = useState({ text: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const formRef = useRef(null);

  const sendMail = (e) => {
    e?.preventDefault();
    console.log(formRef.current);
    if (!name || !email || !message) {
      setText({ text: "ENTER ALL FIELDS SCRRR...", type: "error" });
    } else {
      setText({ text: "", type: "" });
      setLoading(true);

      emailjs
        .sendForm(
          "service_4523r7i",
          "template_2sk8bp6",
          e.target,
          "AVkwXtZRMjqrwBFLA"
        )
        .then((res) => {
          setLoading(false);
          setSent(true);
          setName("");
          setEmail("");
          setMessage("");

          setTimeout(() => {
            setSent(false);
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div className="contact">
      <form className="c-inputs" onSubmit={sendMail} ref={formRef}>
        <h1 className="connect">CONNECT WITH US</h1>
        <h1 className="c-title">CONTACT US</h1>
        <input
          placeholder="NAME"
          className="c-name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          placeholder="EMAIL"
          className="c-name"
          name="user_email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          placeholder="MESSAGE"
          className="c-area"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="pixel-corners2">
          {loading ? (
            <div className="c-btn pixel-corners3">
              <ReactLoading type="cubes" color="#f32735" />
            </div>
          ) : !sent ? (
            <input
              type="submit"
              className="c-btn pixel-corners3"
              value="SEND"
            />
          ) : (
            <div className="c-btn pixel-corners3">
              <img src={sentImg} alt="sent" className="c-sent" />
            </div>
          )}
        </div>

        <h1
          style={{ color: text.type === "error" ? "#f32735" : "#000" }}
          className="c-status"
        >
          {text.text}
        </h1>
      </form>
      <img src={logo} alt="logo" className="contact-logo" />

      <div className="scroll">
        <img src={scroll} alt="scroll" />
        <a href="#top">
          <h1>SCROLL TO TOP</h1>
        </a>
      </div>
    </div>
  );
};

export default Contact;
