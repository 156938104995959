import React from "react";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Intro from "./pages/Intro";
import Skillpage from "./pages/Skillpage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/skills/:id",
    element: <Skillpage />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
