import { useState } from "react";
import styles from "./GridItem.module.css";
import anime from "animejs/lib/anime.es.js";

const GridItem = ({ index, isSelected }) => {
  const [isClicked, setIsClicked] = useState(false);

  const columns = 50;
  const rows = 50;
  const onClickHandler = () => {
    var anim = anime({
      targets: ".grid-item",

      backgroundColor: "rgba(243, 39, 53, 1)",
      delay: anime.stagger(15, {
        grid: [columns, rows],
        from: index,
        easing: "easeInQuad",
      }),
    });

    anim.play();
    anim.finished.then(() => {
      anime({
        targets: ".grid-item",

        backgroundColor: "rgba(229,57,53,0)",
        delay: anime.stagger(10, {
          grid: [columns, rows],
          from: index,
          direction: "reverse",
        }),
      });
    });

    setIsClicked((prevState) => {
      return !prevState;
    });
  };
  return (
    <div
      className={`grid-item ${isSelected ? styles["clicked"] : ""}`}
      onClick={onClickHandler}
    >
      {/* {index} */}
    </div>
  );
};
export default GridItem;
