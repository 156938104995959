import React, { useEffect, useState } from "react";
import nbitem1 from "../../assets/images/nbitem1.svg";
import nbitem2 from "../../assets/images/nbitem2.svg";

const NavAnim = () => {
  const [pixs, setPixs] = useState([]);
  const [track, setTrack] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setPixs([]);
      setTrack((prevState) => !prevState);
    }, 1250);
  }, []);

  useEffect(() => {
    let res = [];
    let random = Math.floor(Math.random() * 4) + 1; // 1
    let pos = Math.floor(Math.random() * (6 - random)) - 1; // 4
    pos = pos < 0 ? 0 : pos;
    let right = Math.random() > 0.5 ? true : false;

    for (let i = 0; i < 6 - random; i++) {
      if (pos === i) {
        for (let j = 0; j < random; j++) {
          res.push({ i, right });
        }
      } else {
        res.push({ i });
      }
    }

    setPixs(res);
  }, [track]);

  return (
    <div className="navAnim">
      {pixs.map((el) => (
        <div
          key={el.i * Math.random()}
          className={
            el.hasOwnProperty("right") ? (el.right ? "end" : "start") : "navPix"
          }
        />
      ))}
    </div>
  );
};

const Navbar = () => {
  return (
    <div className="nb">
      <a href="#services">OUR SERVICES</a>
      {<NavAnim />}
      <a href="#who">WHO WE ARE</a>
      {<NavAnim />}
      <a href="#contact">CONTACT US</a>
    </div>
  );
};

export default Navbar;
