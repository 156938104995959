import React, { useEffect, useRef, useState } from "react";
import "../components/Intro/intro.css";
import scr from "../assets/images/dino-run-0.png";
import scr1 from "../assets/images/dino-run-1.png";
import scr2 from "../assets/images/dino-run-2.png";
import scr3 from "../assets/images/dino-run-3.png";
import scr4 from "../assets/images/dino-run-4.png";
import scr5 from "../assets/images/dino-run-5.png";
import scr6 from "../assets/images/dino-run-6.png";
import scr7 from "../assets/images/ghost-1.png";
import scr8 from "../assets/images/ghost-2.png";
import scr9 from "../assets/images/wink-1.png";
import scr10 from "../assets/images/wink-2.png";
import idle from "../assets/images/idle.png";
import idle2 from "../assets/images/idle2.png";

function getWindowSize() {
  const { innerWidth } = window;
  return innerWidth;
}
const Intro = ({ setIntroFinished }) => {
  const [precent, setPrecent] = useState(0);
  const [img, setImg] = useState(scr);
  const [leftMargin, setLeftMargin] = useState(0);
  const [counter, setCounter] = useState(0);
  const [start, setStart] = useState(true);
  const [scrText, setScrText] = useState("CHOOSE ONE!");
  const broScrr = useRef(null);
  const [hello, setHello] = useState(0);
  const [nameIs, setMyNameIs] = useState(0);
  const [scrr, setScrr] = useState(0);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const world1 = document.getElementsByClassName("world");

  useEffect(() => {
    setTimeout(() => {
      if (start) {
        setPrecent((prevState) =>
          prevState > 60 ? prevState + 0.5 : prevState + 0.1
        );
      }
    }, 6);
  }, [precent, start]);
  useEffect(() => {
    console.log(world1);
    if (world1[0]) {
      world1[0].style.display = "none";
    }
  }, []);

  useEffect(() => {
    if (precent > 15 && "HELLO".slice(0, hello) !== "HELLO") {
      setStart(false);

      setTimeout(() => {
        setImg(idle);
      }, 200);

      setTimeout(() => {
        setImg(idle2);
      }, 600);

      setTimeout(() => {
        setImg(idle);
      }, 1000);

      for (let i = 0; i < 7; i++) {
        setTimeout(() => {
          setHello(i);
        }, i * 150);
      }

      setTimeout(() => {
        setStart(true);
      }, 1200);
    }

    let rightCondition =
      windowSize > 600
        ? "MY NAME IS".slice(0, nameIs) !== "MY NAME IS"
        : "NAME".slice(0, nameIs) !== "NAME";

    if (precent > 30 && rightCondition) {
      setStart(false);

      setTimeout(() => {
        setImg(idle);
      }, 200);

      setTimeout(() => {
        setImg(idle2);
      }, 400);

      setTimeout(() => {
        setImg(idle);
      }, 600);

      if (windowSize > 600) {
        setTimeout(() => {
          setImg(idle2);
        }, 800);

        setTimeout(() => {
          setImg(idle);
        }, 1000);

        setTimeout(() => {
          setImg(idle);
        }, 1200);
        setTimeout(() => {
          setImg(idle);
        }, 1400);

        setTimeout(() => {
          setImg(idle);
        }, 1600);
      }

      let length = windowSize > 600 ? 13 : 5;

      for (let i = 0; i < length; i++) {
        setTimeout(() => {
          setMyNameIs(i);
        }, i * 150);
      }

      setTimeout(() => {
        setStart(true);
      }, (length + 1) * 150);
    }
    if (precent > 45 && "SCRRR...".slice(0, scrr) !== "SCRRR...") {
      setStart(false);

      setTimeout(() => {
        setImg(idle);
      }, 250);

      setTimeout(() => {
        setImg(idle2);
      }, 750);
      setTimeout(() => {
        setImg(idle);
      }, 1200);

      for (let i = 0; i < 9; i++) {
        setTimeout(() => {
          setScrr(i);
        }, i * 150);
      }

      setTimeout(() => {
        setStart(true);
      }, 1500);
    }

    if (precent > 60 && scrText === "CHOOSE ONE!") {
      setStart(false);
    }

    if (windowSize > 600 && precent < 61) {
      setLeftMargin(
        precent - 100 / (window.innerWidth / broScrr.current.clientWidth)
      );
    }

    if (precent >= 100) {
      setIntroFinished(true);
      world1[0].style.display = "Block";
    }
  }, [precent]);

  useEffect(() => {
    if (precent > 60 && scrText !== "Great Choice!") {
      setTimeout(() => {
        if (counter % 2 === 0) {
          setImg(idle);
        } else {
          setImg(idle2);
        }
        setCounter((prevState) => prevState + 1);
      }, 500);
    }
  }, [start, counter]);

  useEffect(() => {
    if (!start) return;

    setTimeout(() => {
      if (counter === 0) {
        setImg(scr);
      } else if (counter === 1) {
        setImg(scr1);
      } else if (counter === 2) {
        setImg(scr2);
      } else if (counter === 3) {
        setImg(scr3);
      } else if (counter === 4) {
        setImg(scr4);
      } else if (counter === 5) {
        setImg(scr5);
      } else if (counter === 6) {
        setImg(scr6);
      }

      if (counter === 6) {
        setCounter(0);
      } else if (counter < 6) {
        setCounter((prevState) => prevState + 1);
      }
    }, 100);
  }, [counter, start]);

  const endIntro = () => {
    setScrText("Great Choice!");

    setTimeout(() => {
      setImg(scr9);
    }, 400);
    setTimeout(() => {
      setImg(scr10);
    }, 800);
    setTimeout(() => {
      setImg(scr9);
    }, 1100);
    setTimeout(() => {
      setImg(scr7);
    }, 1300);
    setTimeout(() => {
      setImg(scr8);
    }, 1900);
    setTimeout(() => {
      setStart(true);
    }, 2600);
  };

  return (
    <div className="intro">
      <div className="i-load">
        {precent < 61 && (
          <div
            className="scr-man"
            style={{
              marginLeft: windowSize > 600 ? `${leftMargin}vw` : 0,
            }}
          >
            <div className="lh-wrapper">
              {precent >= 15 && precent <= 16 && (
                <div className="left-hello">
                  <h1>{"HELLO".slice(0, hello)}</h1>
                </div>
              )}
              {precent >= 15 && precent <= 16 && <div className="lh-b" />}
            </div>

            {precent < 61 && (
              <img src={img} alt="SCRRRR" ref={broScrr} className="scr" />
            )}
            {precent > 16 && precent < 31 && (
              <div className="rh-wrapper">
                {precent >= 30 && precent <= 31 && (
                  <div className="right-hello">
                    <h1>
                      {windowSize > 600
                        ? "MY NAME IS".slice(0, nameIs)
                        : "I AM".slice(0, nameIs)}
                    </h1>
                  </div>
                )}
                {precent >= 30 && precent <= 31 && <div className="rh-b" />}
              </div>
            )}
            {precent > 31 && precent < 60 && (
              <div className="rn-wrapper">
                {precent >= 45 && precent <= 46 && (
                  <div className="right-name">
                    <h1>{"SCRRR...".slice(0, scrr)}</h1>
                  </div>
                )}
                {precent >= 45 && precent <= 46 && <div className="rn-b" />}
              </div>
            )}
            {precent >= 60 && !start && (
              <div
                style={{ left: `${leftMargin + 8}vw` }}
                className="choose-one"
              >
                <h1>{scrText}</h1>
                <div className="scr-theme">
                  <div
                    className="theme-left"
                    onClick={() => setScrText("No, dark is better!")}
                  />
                  <div className="theme-right" onClick={endIntro} />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="load">
          <div className="fill" style={{ width: `${precent * 1.5}vw` }} />
          {precent < 90 && (
            <p className="precent">
              {Math.round(precent < 100 / 1.63 ? precent * 1.63 : 100) + "%"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Intro;
