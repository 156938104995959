import React, { useState } from "react";
import "./navbtn.css";
import { Divide as Hamburger } from "hamburger-react";
import fb from "../../assets/images/fb.svg";
import tt from "../../assets/images/tt.svg";
import li from "../../assets/images/li.svg";

const MobileNavbar = ({ color }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [className, setClassName] = useState("phonenav");

  const handleToggle = () => {
    if (isOpen) {
      setClassName("outnb");
      setTimeout(() => {
        setIsOpen(false);
      }, 250);
    } else {
      setClassName("phonenav");
      setIsOpen(true);
    }
  };

  return (
    <>
      <div
        className="mnb"
        style={isOpen ? { position: "fixed", right: 15, top: 15 } : {}}
      >
        <Hamburger toggled={isOpen} onToggle={handleToggle} color={color} />
      </div>
      {isOpen && (
        <div className={className}>
          <div className="mnb-i" style={{ visibility: "hidden" }}>
            <img alt="facebook" src={fb} />
            <img alt="twitter" src={tt} style={{ marginInline: 25 }} />
            <img alt="linkedin" src={li} />
          </div>
          <div>
            <a href="#services" onClick={() => handleToggle()}>
              <h1 className="mnb-t">OUR SERVICES</h1>
            </a>
            <a href="#who" onClick={() => handleToggle()}>
              <h1 className="mnb-t">WHO WE ARE</h1>
            </a>
            <a href="#contact" onClick={() => handleToggle()}>
              <h1 className="mnb-t">CONTACT US</h1>
            </a>
          </div>
          <div className="mnb-i">
            <a
              href="https://www.facebook.com/scriptify.global"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="facebook" src={fb} width={55} />
            </a>
            <a
              href="https://www.linkedin.com/company/scriptify-global"
              target="_blank"
              rel="noreferrer"
              style={{ marginBlock: 25, marginInline: "25px" }}
            >
              <img alt="linkedin" src={li} width={55} />
            </a>
            <a
              href="https://twitter.com/scriptifyglobal"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="twitter" src={tt} width={55} />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNavbar;
