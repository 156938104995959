import React, { useEffect, useState } from "react";
import "../components/skill/skill.css";
import reactlogo from "../assets/images/react.svg";
import tw from "../assets/images/tailwind.png";
import eslint from "../assets/images/eslint.png";
import ts from "../assets/images/ts.png";
import next from "../assets/images/next.png";
import illustrator from "../assets/images/illustrator.png";
import photoshop from "../assets/images/photoshop.png";
import xd from "../assets/images/adobe-xd.png";
import figma from "../assets/images/figma.png";
import unity from "../assets/images/unity.png";
import unreal from "../assets/images/unreal.png";
import blender from "../assets/images/blender.png";
import psql from "../assets/images/PostgreSQL.png";
import node from "../assets/images/nodejs.webp";
import laravel from "../assets/images/laravel.png";
import express from "../assets/images/Expressjs.png";
import sql from "../assets/images/MySQL.png";
import cl from "../assets/images/Chainlink.png";
import alchemy from "../assets/images/alchemy.png";
import mdb from "../assets/images/mongoDB.png";
import hardhat from "../assets/images/hardhat.png";
import openzeppelin from "../assets/images/openzeppelin.png";
import solidity from "../assets/images/solidity.png";

import { useParams } from "react-router-dom";

const NavAnim = () => {
  const [pixs, setPixs] = useState([]);
  const [track, setTrack] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setPixs([]);
      setTrack((prevState) => !prevState);
    }, 1250);
  }, []);

  useEffect(() => {
    let length = window.innerWidth > 600 ? 60 : 30;
    let length2 = window.innerWidth > 600 ? 40 : 20;
    let res = [];
    let random = Math.floor(Math.random() * length2); // 1
    let pos = Math.floor(Math.random() * (length - random)) - 1; // 4
    pos = pos < 0 ? 0 : pos;
    let right = Math.random() > 0.5 ? true : false;

    for (let i = 0; i < length - random; i++) {
      if (pos === i) {
        for (let j = 0; j < random; j++) {
          res.push({ i, right });
        }
      } else {
        res.push({ i });
      }
    }

    setPixs(res);
  }, [track]);

  return (
    <div className="skillAnim">
      {pixs.map((el) => (
        <div
          key={el.i * Math.random()}
          className={
            el.hasOwnProperty("right")
              ? el.right
                ? "end-s"
                : "start-s"
              : "skillPix"
          }
        />
      ))}
    </div>
  );
};

const Skillpage = () => {
  const { id } = useParams();

  const title =
    id === "design"
      ? "DESIGN"
      : id === "game"
      ? "GAME"
      : id === "web"
      ? "WEB / MOBILE"
      : id === "blockchain"
      ? "BLOCKCHAIN"
      : "";

  const leftTitle =
    id === "design"
      ? "UX/UI"
      : id === "game"
      ? "2D"
      : id === "web"
      ? "FRONT"
      : id === "blockchain"
      ? "SMART CONTRACT"
      : "";

  const rightTitle =
    id === "design"
      ? "BRANDING"
      : id === "game"
      ? "3D"
      : id === "web"
      ? "BACK"
      : id === "blockchain"
      ? "WEB3"
      : "";

  const left =
    id === "web" || id === "mobile"
      ? [
          { img: reactlogo, name: "REACT JS" },
          { img: ts, name: "TYPESCRIPT" },
          { img: reactlogo, name: "REACT NATIVE" },
          { img: tw, name: "TAILWIND" },
          { img: next, name: "NEXT JS" },
          { img: eslint, name: "ESLINT" },
        ]
      : id === "design"
      ? [
          { img: figma, name: "FIGMA" },
          { img: xd, name: "ADOBE XD" },
        ]
      : id === "game"
      ? [{ img: unity, name: "UNITY" }]
      : id === "blockchain"
      ? [
          { img: solidity, name: "SOLIDITY" },
          { img: hardhat, name: "HARDHAT" },
          { img: openzeppelin, name: "OPENZEPPELIN" },
        ]
      : [];

  const right =
    id === "design"
      ? [
          { img: illustrator, name: "ILLUSTRATOR" },
          { img: photoshop, name: "PHOTOSHOP" },
        ]
      : id === "game"
      ? [
          { img: unreal, name: "UNREAL ENGINE" },
          { img: blender, name: "BLENDER" },
        ]
      : id === "web"
      ? [
          { img: node, name: "NODE JS" },
          { img: express, name: "EXPRESS JS", resize: true },
          { img: laravel, name: "LARAVEL" },
          { img: psql, name: "POSTGRESQL" },
          { img: sql, name: "MYSQL" },
          { img: mdb, name: "MONGODB" },
        ]
      : id === "blockchain"
      ? [
          { img: alchemy, name: "ALCHEMY" },
          { img: cl, name: "CHAINLINK" },
        ]
      : [];

  return (
    <div className="skill">
      <h1 className="skill-title">{title}</h1>

      <div className="skill-content">
        <div className="skill-left">
          <h1>{leftTitle}</h1>
          <div className="skill-icons">
            {left.map((el) => (
              <div className="skill-icon" key={el.name.length * Math.random()}>
                <div className="si-wrapper">
                  <img src={el.img} alt="skill" />
                </div>
                <h1>{el.name}</h1>
              </div>
            ))}
          </div>
        </div>
        <NavAnim />
        <div className="skill-right">
          <h1>{rightTitle}</h1>
          <div className="skill-icons">
            {right.map((el) => (
              <div className="skill-icon" key={el.name.length * Math.random()}>
                <div className="si-wrapper">
                  <img
                    src={el.img}
                    alt="skill"
                    style={el.resize && { transform: "scale(0.75)" }}
                  />
                </div>
                <h1>{el.name}</h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skillpage;
