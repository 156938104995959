import React, { useEffect, useState } from "react";
import Welcome from "../components/welcome/Welcome";
import WhoWeAre from "../components/whoweare/whoweare";
import Contact from "../components/contacts/Contact";
import Footer from "../components/footer/Footer";
import Intro from "./Intro";

const Homepage = () => {
  const [introFinished, setIntroFinished] = useState(false);

  const isFirstVisit = () => {
    if (localStorage.getItem("scr") === "yes") {
      return false;
    }

    return true;
  };

  return isFirstVisit() && !introFinished ? (
    <Intro setIntroFinished={setIntroFinished} />
  ) : (
    <div>
      <Welcome />
      <WhoWeAre />

      <Contact />
      <Footer />
    </div>
  );
};

export default Homepage;
