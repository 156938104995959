import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import "./welcome.css";
import logo from "../../assets/images/logo-icon.svg";
import fb from "../../assets/images/fb.svg";
import tt from "../../assets/images/tt.svg";
import li from "../../assets/images/li.svg";
import fbh from "../../assets/images/facebook-hover.png";
import tth from "../../assets/images/twitter-hover.png";
import lih from "../../assets/images/linkedin-hover.png";
import Services from "./Services";
import { TypeAnimation } from "react-type-animation";
import MobileNavbar from "./MobileNavbar";

const ExampleComponent = ({ text1, text2, text3, waitTime, style = {} }) => {
  return (
    <TypeAnimation
      sequence={[
        waitTime,
        text1, // Types 'One'
        3500, // Waits 1s
        text2, // Deletes 'One' and types 'Two'
        4500, // Waits 2s
        text3, // Types 'Three' without deleting 'Two'
        () => {
          console.log("Done typing!"); // Place optional callbacks anywhere in the array
        },
      ]}
      wrapper="div"
      cursor={true}
      repeat={Infinity}
      className="type"
      style={style}
    />
  );
};

const Welcome = () => {
  // const [scrollPosition, setScrollPosition] = useState(0);
  const wcRef = useRef(null);
  const [fbs, setFb] = useState(false);
  const [tts, setTt] = useState(false);
  const [lis, setLi] = useState(false);

  useEffect(() => {
    localStorage.setItem("scr", "yes");
  }, []);

  const world = document.getElementsByClassName("world");
  useEffect(() => {
    setTimeout(() => {
      if (world[0]) {
        world[0].style.display = "block";
      }
    }, 200);
  });

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div className="wc" ref={wcRef}>
      <div id="top" />
      <Navbar />
      <MobileNavbar color={"#fff"} />
      <div className="welcome-screen">
        <div className="wc-center">
          <img src={logo} alt="SCRIPTIFY" className="logo" />
        </div>

        <div className="s-icons">
          <a
            href="https://www.facebook.com/scriptify.global"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="facebook"
              src={fbs ? fbh : fb}
              onMouseEnter={() => setFb(true)}
              onMouseLeave={() => setFb(false)}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/scriptify-global"
            target="_blank"
            rel="noreferrer"
            style={{ marginBlock: 25 }}
          >
            <img
              alt="linkedin"
              src={lis ? lih : li}
              onMouseEnter={() => setLi(true)}
              onMouseLeave={() => setLi(false)}
            />
          </a>
          <a
            href="https://twitter.com/scriptifyglobal"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="twitter"
              src={tts ? tth : tt}
              onMouseEnter={() => setTt(true)}
              onMouseLeave={() => setTt(false)}
            />
          </a>
        </div>
        <div className="subtext">
          <h3 className="wc-sh">
            <TypeAnimation
              sequence={[
                "[YOUR IDEA]", // Types 'One'
                500, // Waits 1s
                "[YOUR FUTURE]", // Deletes 'One' and types 'Two'
                500, // Waits 2s
                "[YOUR BRAND]", // Types 'Three' without deleting 'Two'
                () => {
                  console.log("Done typing!"); // Place optional callbacks anywhere in the array
                },
              ]}
              // wrapper="div"
              cursor={false}
              repeat={0}
              className="header-type"
            />
          </h3>
        </div>
        <div className="code-texts">
          <div className="holder" />
          <div className="wcbr-top">
            <ExampleComponent
              text1={"console.log('HELLO WORLD!')"}
              text2={"b8 21 0a 00 00"}
              text3={"01001000 01100101"}
              waitTime={2000}
            />
            <ExampleComponent
              text1={"using UnityEngine.SceneManagement;"}
              text2={"SM.LoadScene('StartScreen'); "}
              text3={"player.GetComponent<Rigidbody>();"}
              waitTime={3000}
            />
            <ExampleComponent
              text1={"import React from 'react';"}
              text2={"const Welcome =()=>{};"}
              text3={"export default Welcome;"}
              waitTime={4000}
            />
            <ExampleComponent
              text1={"pragma solidity^0.4.0;"}
              text2={"uint256 highscore;"}
              text3={"return Highscore;"}
              waitTime={5000}
            />
            <ExampleComponent
              text1={"print('This is scriptify')"}
              text2={"0D 0A 00 C9"}
              text3={"1100001001"}
              waitTime={6000}
            />
          </div>
        </div>
      </div>
      <div id="services" />

      <Services />
      <div id="who" />
    </div>
  );
};

export default Welcome;
