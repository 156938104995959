import React, { useState } from "react";
import "./footer.css";
import logo from "../../assets/images/logo-f.svg";
import location from "../../assets/images/location.svg";
import phone from "../../assets/images/phone.svg";
import fb from "../../assets/images/fb.svg";
import tt from "../../assets/images/tt.svg";
import li from "../../assets/images/li.svg";
import fbh from "../../assets/images/facebook-hover.png";
import tth from "../../assets/images/twitter-hover.png";
import lih from "../../assets/images/linkedin-hover.png";

const Footer = () => {
  const [fbs, setFb] = useState(false);
  const [tts, setTt] = useState(false);
  const [lis, setLi] = useState(false);

  return window.innerWidth > 600 ? (
    <div className="footer">
      <div className="f-left">
        <img src={logo} alt="logo" className="logo-f" />
        <h1>2022 [C] All Rights Reserved</h1>
      </div>

      <div className="num-loc">
        <div className="f-phone">
          <img src={phone} alt="phone" className="icon" />
          <h1>+995 571 39 39 09</h1>
        </div>
        <div className="f-location">
          <img src={location} alt="location" className="icon" />
          <h1>
            82 Chavchavadze Ave.
            <br />
            0162 Tbilisi, Georgia
          </h1>
        </div>
      </div>
      <div className="fs-icons">
        <a
          href="https://www.facebook.com/scriptify.global"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="facebook"
            src={fbs ? fbh : fb}
            onMouseEnter={() => setFb(true)}
            onMouseLeave={() => setFb(false)}
            className="icon"
          />
        </a>

        <a
          href="https://www.linkedin.com/company/scriptify-global"
          target="_blank"
          rel="noreferrer"
          style={{ marginInline: 14 }}
        >
          <img
            alt="linkedin"
            src={lis ? lih : li}
            onMouseEnter={() => setLi(true)}
            onMouseLeave={() => setLi(false)}
            className="icon"
          />
        </a>
        <a
          href="https://twitter.com/scriptifyglobal"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="twitter"
            src={tts ? tth : tt}
            onMouseEnter={() => setTt(true)}
            onMouseLeave={() => setTt(false)}
            className="icon"
          />
        </a>
      </div>
    </div>
  ) : (
    <div className="footer-phone">
      <div>
        <div className="fp-top">
          <div className="ft-top-l">
            <div className="fp-phone">
              <img src={phone} alt="phone" />
              <h1>+995 571 39 39 09</h1>
            </div>
            <div className="fp-phone">
              <img src={location} alt="phone" />
              <h1>
                82 Chavchavadze Ave.
                <br />
                0162 Tbilisi, Georgia
              </h1>
            </div>
          </div>
          <div className="fp-icons">
            <a
              href="https://www.facebook.com/scriptify.global"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="facebook"
                src={fbs ? fbh : fb}
                onMouseEnter={() => setFb(true)}
                onMouseLeave={() => setFb(false)}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/scriptify-global"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="linkedin"
                src={lis ? lih : li}
                onMouseEnter={() => setLi(true)}
                onMouseLeave={() => setLi(false)}
              />
            </a>
            <a
              href="https://twitter.com/scriptifyglobal"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="twitter"
                src={tts ? tth : tt}
                onMouseEnter={() => setTt(true)}
                onMouseLeave={() => setTt(false)}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="fp-logo">
        <img src={logo} alt="SCRIPTIFY" />
        <h1>2022 [C] All Rights Reserved</h1>
      </div>
    </div>
  );
};

export default Footer;
